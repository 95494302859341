import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  // Check if filter object have any value
  filterisEmpty(filter: any): boolean {
    let status = true;
    for (const key of Object.keys(filter)) {
      if (filter[key]) {
        status = false;
        break;
      }
    }
    return status;
  }
}
